import { computed, makeObservable, flow, action } from "mobx";
import { AxiosResponse } from "axios";
import { ICpnStore } from "./ICpn";
import { isNil } from "ms_data";
import { FormStore } from "ms-ui";
import { reg_cpn_by_promo_cd } from "src/repository/Pay/cpn";

export class PromoStore extends FormStore<ICpnStore> {
  constructor() {
    super();
    makeObservable(this, {
      inputValidate: computed,
      btnValidate: computed,
      isPblsh: computed,
      init: action,
      reg: flow,
    });

    this.init();
  }

  get inputValidate() {
    const { promoCd } = this.form;

    return isNil(promoCd) || (!isNil(promoCd) && promoCd?.length === 8);
  }

  get btnValidate() {
    const { promoCd } = this.form;
    return !isNil(promoCd) && promoCd?.length === 8;
  }

  get isPblsh() {
    const { pblsh } = this.form;
    return pblsh === true;
  }

  init = () => {
    this.initForm({
      promoCd: "",
      comment: "프로모션코드는 영문자, 숫자 포함 8글자로 이루어져 있습니다.",
      pblsh: false,
    });
  };

  *reg() {
    try {
      this.setLoading(true);

      const res: AxiosResponse<{ result: boolean; msg: string }> =
        yield reg_cpn_by_promo_cd({ promoCd: this.form.promoCd! }).req();

      return res.data;
    } catch (err) {
      console.log(err);
      return { result: false, msg: "잘못된 쿠폰코드를 입력하셨습니다." };
    } finally {
      this.setLoading(false);
    }
  }
}
