import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  borderRadius,
  contents,
  foundation,
  Body2,
  Subtitle2,
  Caption1,
  Subtitle4,
  Body3,
} from "ms-ui";
import styled from "styled-components";

export interface ICpnCard {
  /**
   * 쿠폰명
   */
  nm: string;

  /**
   * 쿠폰 금액
   */
  amount: string;

  /**
   * 쿠폰 제한사항
   */
  limit: Props;

  /**
   * 쿠폰 상태 (기간만료 or 사용완료)
   */
  status: Props;

  /**
   * 남은 기한
   */
  day: Props;

  /**
   * 쿠폰 기한
   */
  period: string;
}

export interface Props {
  visible: boolean;
  msg: string;
  color?: string;
}

export const CpnCard = observer(
  ({ nm, amount, limit, status, day, period }: ICpnCard) => {
    return (
      <>
        <StDiv1>
          <StDiv2>{nm}</StDiv2>
          <StDiv3>
            <StDiv4>{amount}</StDiv4>
          </StDiv3>
          {limit.visible && <StDiv5>{limit.msg}</StDiv5>}
          <StDiv6>
            <StDiv7>{status.visible && <StDiv8>{status.msg}</StDiv8>}</StDiv7>
            <StDiv9>
              {day.visible && (
                <StDiv10 style={{ color: day.color }}>{day.msg}</StDiv10>
              )}
              <StDiv11>{period}</StDiv11>
            </StDiv9>
          </StDiv6>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  border-radius: ${borderRadius[10].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Body2};
  color: ${foundation.normal.black.value};
  text-align: center;
  width: fit-content;
`;

const StDiv3 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Subtitle2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Caption1};
  align-self: stretch;
  color: ${contents.description.value};
`;

const StDiv6 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv7 = styled.div`
  display: flex;
  gap: 10px;
  width: fit-content;
`;

const StDiv8 = styled.div`
  ${Caption1};
  color: ${contents.description.value};
  width: fit-content;
`;

const StDiv9 = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacing[8].value};
  width: fit-content;
`;

const StDiv10 = styled.div`
  ${Subtitle4};
  color: ${foundation.normal.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv11 = styled.div`
  ${Body3};
  color: ${contents.description.value};
  text-align: center;
  width: fit-content;
`;
