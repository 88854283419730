import { observer } from "mobx-react";
import styled from "styled-components";
import { BottomFixed, Button, More, spacing } from "ms-ui";
import { RLink } from "ms_router";

export const BottomBtn = observer(() => {
  return (
    <>
      {/* 내 출근길 찾아보기 Btn */}
      <BottomFixed>
        <RLink to={`/search/start_form`}>
          <Button
            size={`rg`}
            radius={"0"}
            width="100%"
            style={{ height: "50px" }}
          >
            <StBtnMore>
              <div>내 출근길 찾아보기</div>
              <More color={`var(--contents-secondary)`} />
            </StBtnMore>
          </Button>
        </RLink>
      </BottomFixed>
    </>
  );
});

const StBtnMore = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  svg {
    margin-left: ${spacing[8].value};
  }
`;
