import React from "react";
import { observer } from "mobx-react";
import { useURI } from "ms_router";
import { ICpnProps } from "src/pages/Mypage/Coupon/Store/ICpn";
import { EmptyList } from "src/pages/Mypage/ApplyDetail/Style";
import { CpnCard } from "./CpnCard";
import { CpnLogic } from "../../CpnLogic";
import { isNil, mComma } from "ms_data";
import { intervalDay } from "src/func/moment";
import { foundation } from "ms-ui";

export const List: React.FC<ICpnProps> = observer((props: ICpnProps) => {
  const { data } = props.cpnStore;
  const q = useURI().getQuery<{ tab: "0" | "1" }>();

  return (
    <>
      {data?.cpnList.length! > 0 ? (
        <>
          {data?.cpnList.map((obj, i) => {
            const { cpn, cpnLimit } = obj;
            const { limitDetail, cpnStatus } = new CpnLogic({
              cpn,
              cpnLimit,
              serviceCd: data?.serviceCd!,
            });

            return (
              <CpnCard
                key={i}
                nm={cpn.nm}
                amount={
                  cpn.calCd === 1
                    ? `${
                        new Function(`return ${cpn.func.replace("v", "1")}`)() *
                        100
                      }%`
                    : `${mComma(cpn.amount)}원`
                }
                limit={{
                  visible: !isNil(cpnLimit),
                  msg: limitDetail().msg,
                }}
                status={{
                  visible: q.tab === "0",
                  msg: cpnStatus?.ko || ``,
                }}
                day={{
                  visible:
                    q.tab === "1" &&
                    !isNil(cpn.endDay) &&
                    cpnStatus?.ko !== "사용대기",
                  msg: `D-${
                    intervalDay(cpn.endDay) === 0
                      ? "Day"
                      : intervalDay(cpn.endDay)
                  }`,
                  color:
                    intervalDay(cpn.endDay) >= 7
                      ? foundation.normal.primary.value
                      : foundation.normal.negative.value,
                }}
                period={cpnStatus?.txt || ``}
              />
            );
          })}
        </>
      ) : (
        <EmptyList>
          {q.tab === "1" ? "사용가능한 " : "지난 "}쿠폰이 없습니다.
        </EmptyList>
      )}
    </>
  );
});
