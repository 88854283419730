import React from "react";
import { borderRadius, contents, spacing } from "ms-ui";
import styled from "styled-components";
import Skeleton from "src/components/Skeleton";

export const SkeletonList: React.FC = () => {
  return (
    <StCouponContent>
      <Comp />
      <Comp />
      <Comp />
    </StCouponContent>
  );
};

const Comp: React.FC = () => {
  return (
    <StSklCouponCard>
      <Skeleton
        style={{ width: "46px", height: "14px", borderRadius: "15px" }}
      />
      <Skeleton
        style={{ width: "179px", height: "14px", borderRadius: "15px" }}
      />
      <Skeleton
        style={{ width: "179px", height: "12px", borderRadius: "15px" }}
      />
      <Skeleton
        style={{
          width: "180px",
          height: "12px",
          borderRadius: "15px",
          margin: "0 auto",
          marginRight: "32px",
        }}
      />
    </StSklCouponCard>
  );
};

const StSklCouponCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  background-color: ${contents.secondary.value};
  border-radius: ${borderRadius[10].value};
  padding: ${spacing[16].value};
`;

const StCouponContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
`;
