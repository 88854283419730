import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { CpnList } from "./Comp/CpnList";
import { ITab, Tabs } from "./Comp/CpnList/Comp";
import { BottomBtn } from "./Comp/BottomBtn";
import { CpnStore, PromoStore } from "./Store";
import { isNil } from "ms_data";
import styled from "styled-components";
import { MediaQuery, Nav, TabGroup } from "ms-ui";
import { useURI } from "ms_router";
import { BackHistory } from "src/func/url";
import { naviEvtId } from "src/constants/evt/nav";
import { PromoTpl } from "./Comp/PromoTpl";

export const CpnBox = observer(() => {
  const { navigate, getQuery, changeQuery } = useURI();
  const [promoStore] = React.useState(new PromoStore()); // 프로모션 쿠폰 발급 관련
  const [cpnStore] = React.useState(new CpnStore()); // 쿠폰 서버 스토어
  const q: { tab: "1" | "0" } = getQuery();

  const [tab, setTab] = useState<"1" | "0">(q.tab ?? "1");

  useEffect(() => {
    // tab정보가 없으면 기본 값인 1로 지정해줌
    if (isNil(q.tab)) changeQuery({ tab: "1" }, true);
  }, []);

  const fixedRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(fixedRef.current?.offsetHeight!);
  }, [promoStore.form.promoCd]);

  useEffect(() => {
    changeQuery({ tab }, true);
  }, [tab]);

  return (
    <>
      <StFixed ref={fixedRef}>
        <Nav
          title={`쿠폰함`}
          left={{
            type: "back",
            onClick: () => BackHistory(navigate, "/main"),
            // id: naviEvtId.navi_back.key,
          }}
        />

        {/* 프로모션 코드 */}
        <PromoTpl
          promoStore={promoStore}
          cpnStore={cpnStore}
          tab={tab}
          setTab={setTab}
        />

        {/* 쿠폰 탭 */}
        <TabGroup
          tabs={Tabs.map((item: ITab) => {
            const { key, text, id } = item;
            return {
              key,
              text,
              onClick: () => {
                if (tab !== item.key) {
                  setTab(tab === "0" ? "1" : "0");
                }
              },
              id,
            };
          })}
          selectedKey={tab}
        />
      </StFixed>

      {/* 쿠폰 리스트 */}
      <CpnList
        promoStore={promoStore}
        cpnStore={cpnStore}
        tab={tab}
        paddingTop={height}
      />

      {/* 내 출근길 찾아보기 Btn */}
      <BottomBtn />
    </>
  );
});

export default CpnBox;

const StFixed = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  ${MediaQuery}
  z-index: 10;
`;
