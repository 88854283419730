/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetCpn  } from './interface/res/get/Res.Get.Cpn';
import { ResGetCpnList  } from './interface/res/get/Res.Get.CpnList';
import { ReqPostCpnPromo  } from './interface/req/post/Req.Post.CpnPromo';
import { ResPostCpnPromo  } from './interface/res/post/Res.Post.CpnPromo';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Cpn {

/**
 * 사용 가능한 쿠폰 리스트

*/
get_cpn_list_by_cpn_id(data: {   payId: string }){
 return GET<ResGetCpn>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/cpn`,
 }, data);
}
/**
 * 나에게 할당된 쿠폰 리스트  
  - **useCd = 0** 사용가능  
  - **useCd = 1** 불가능

*/
get_cpn_list_by_use_cd(data: {   useCd: string }){
 return GET<ResGetCpnList>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/cpn/list`,
 }, data);
}
/**
 * 프로모션 코드를 입력하여 쿠폰을 발급받을수 있다.

*/
reg_cpn_by_promo_cd(data: ReqPostCpnPromo){
 return POST<ResPostCpnPromo>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.pay_resource}`,
   url: `/cpn/promo`,
 }, data);
}
 
}

export const CpnRepository = new Cpn();
export const { get_cpn_list_by_cpn_id, get_cpn_list_by_use_cd, reg_cpn_by_promo_cd } = CpnRepository;
	