import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { ICpnProps } from "../../Store/ICpn";
import { List, SkeletonList } from "./Comp";
import { spacing, contents } from "ms-ui";
import styled from "styled-components";

interface ICpnList extends ICpnProps {
  paddingTop: number;
}

export const CpnList: React.FC<ICpnList> = observer((props: ICpnList) => {
  const { cpnStore, tab, paddingTop } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    cpnStore.loadData(tab);
  }, [tab]);

  return (
    <StCouponList $paddingTop={paddingTop - 198}>
      {cpnStore.isLoading === true ? <SkeletonList /> : <List {...props} />}
    </StCouponList>
  );
});

const StCouponList = styled.div<{ $paddingTop: number }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing[16].value};
  padding: ${spacing[16].value};
  padding-bottom: 66px;
  padding-top: ${(p) => `${p.$paddingTop}px`};
  margin-top: 214px;
  height: calc(100dvh - 214px);
  background-color: ${contents.background.value};
  overflow: auto;
`;
