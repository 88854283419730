import { observer } from "mobx-react";
import { ICpnProps } from "../../Store/ICpn";
import { Promo } from "./Promo";
import { flowResult } from "mobx";
import { isNil } from "ms_data";
import { onEnter } from "src/func/utils";
import { ModalAlert, ModalLoader } from "ms-ui";

export const PromoTpl = observer(
  (props: ICpnProps & { setTab: (t: "0" | "1") => void }) => {
    const { promoStore, cpnStore } = props;
    const { isLoading, form, setForm, inputValidate, btnValidate } = promoStore;

    const reg = async () => {
      const { result, msg } = await flowResult(promoStore.reg());

      if (result === true) {
        promoStore.setForm("pblsh", true);
      }

      promoStore.setAlert({
        msg: !isNil(msg) ? msg : "쿠폰이 발급되었습니다.",
        open: true,
      });
    };

    return (
      <>
        <ModalLoader active={isLoading} />

        <ModalAlert
          msg={promoStore.alert.msg}
          open={promoStore.alert.open}
          confirmMsg="확인"
          onClick={() => {
            if (form.pblsh === true) {
              props.setTab("1");
              cpnStore.loadData(props.tab);
              promoStore.init();
            }
            promoStore.setAlert({ msg: "", open: false });
          }}
        />

        <Promo
          promoInput={{
            maxLength: 8,
            invalid: promoStore.inputValidate !== true,
            placeholder: `코드를 입력해주세요.`,
            value: promoStore.form.promoCd || "",
            onKeyUp: onEnter(reg),
            onChange: (e) => {
              promoStore.setForm("promoCd", e.currentTarget.value);
            },
          }}
          promoButton={{
            disabled: promoStore.btnValidate !== true,
            onClick: reg,
          }}
          promoMsg={{
            active: promoStore.inputValidate !== true,
            msg: promoStore.form.comment || "",
          }}
        />
      </>
    );
  }
);
