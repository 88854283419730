import { mypagecouponEvtId } from "src/constants/evt/mypage/cpn";

export interface ITab {
  key: "0" | "1";
  text: string;
  id?: string;
  "data-id"?: string;
}

export const Tabs: ITab[] = [
  {
    key: "1",
    text: "사용가능한 쿠폰",
    "data-id": "avlblCpn",
    // id: mypagecouponEvtId["tab_coupon-list"].key,
  },
  {
    key: "0",
    text: "지난 쿠폰",
    "data-id": "unvlbCpn",
    // id: mypagecouponEvtId["tab_used-list"].key,
  },
];
