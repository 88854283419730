import { ICpn, ICpnLimit, IServiceCd, mFormat, isNil } from "ms_data";

export interface ICpnObj {
  cpn: ICpn;
  cpnLimit?: ICpnLimit;
}

export interface IServiceCdObj {
  serviceCd: {
    limitCd: IServiceCd;
    rtCd: IServiceCd;
  };
}

export interface ICpnLogic extends ICpnObj, IServiceCdObj {}

// 우선순위가 필요
//  paidCpn객체의 유무
//  useCd의 값
const periodCd = [
  { key: "before", ko: "사용대기", use: false },
  { key: "current", ko: "사용가능", use: false },
  { key: "after", ko: "기간만료", use: false },
  { key: "not", ko: "무기한", use: false },
];

export class CpnLogic {
  _obj: ICpnLogic;

  constructor(obj: ICpnLogic) {
    this._obj = obj;
  }

  get cpnStatus() {
    const { cpn } = this._obj;

    if (cpn.useCd === 1) {
      return this.usePoss;
    } else if (cpn.useCd === 0) {
      return this.useImposs;
    }
    return null;
  }

  get period() {
    const { cpn } = this._obj;
    const cur = mFormat(`YYYY-MM-DD HH:mm:ss`);
    if (!isNil(cpn.startDay) && !isNil(cpn.endDay)) {
      if (cpn.startDay > cur) {
        return periodCd[0];
      } else if (cpn.startDay <= cur && cpn.endDay >= cur) {
        return periodCd[1];
      } else {
        return periodCd[2];
      }
    } else {
      return periodCd[3];
    }
  }

  // 사용 가능 조건
  // 쿠폰을 사용하지 않았을때  use_cd === 1
  get usePoss() {
    // 기간값
    const obj = this.period;
    const { cpn } = this._obj;

    const use: boolean = cpn.useCd === 1;

    if (obj.key === "before") {
      return {
        ...obj,
        txt: `${dateFormatCpn(cpn.startDay)}부터 사용 가능`,
        use,
      };
    } else if (obj.key === "current") {
      return {
        ...obj,
        txt: `${dateFormatCpn(cpn.endDay)}까지`,
        use,
      };
    } else if (obj.key === "after") {
      return {
        ...obj,
        txt: `${dateFormatCpn(cpn.endDay)}까지`,
        use,
      };
    } else if (obj.key === "not") {
      return {
        ...obj,
        txt: `기한 제한 없음`,
        use,
      };
    }
  }

  // 쿠폰을 사용했을때  use_cd === 0
  get useImposs() {
    // 기간값
    const obj = this.period;
    const { cpn } = this._obj;
    const use: boolean = cpn.useCd === 1;

    if (obj.key === "before") {
      return {
        ...obj,
        txt: `${dateFormatCpn(cpn.startDay)}부터 사용 가능`,
        ko: "사용완료",
        use,
      };
    } else if (obj.key === "current") {
      return {
        ...obj,
        txt: `${dateFormatCpn(cpn.endDay)}까지`,
        ko: "사용완료",
        use,
      };
    } else if (obj.key === "after") {
      return {
        txt: `${dateFormatCpn(cpn.endDay)}까지`,
        period: "after",
        ko: "사용완료",
        use,
      };
    } else if (obj.key === "not") {
      return { txt: `무기한`, period: null, ko: "사용완료" };
    }
  }

  get condition() {
    const { cpnLimit, serviceCd } = this._obj;

    let cpnLimitTemp: any = cpnLimit;

    if (!isNil(cpnLimit)) {
      const limitCdobj = serviceCd.limitCd.value.find(
        (c: any) => c.key === cpnLimit?.limitCd
      );

      let where: any = "";
      if (cpnLimit?.limitCd === "rtCd") {
        const rtCdObj = serviceCd.rtCd.value.find(
          (c: any) => c.key === cpnLimit?.targetId
        );

        where = rtCdObj.ko;
      } else {
        where = cpnLimit?.targetId;
      }

      cpnLimitTemp = {
        ...cpnLimitTemp,
        ko: limitCdobj?.ko,
        txt: `${limitCdobj?.ko} ${where}일 경우만 사용 가능`,
      };
    }

    return {
      cpnLimit: cpnLimitTemp,
    };
  }

  get periodTxt() {
    const { cpn } = this._obj;
    const obj = this.period;

    if (obj.key === "not") {
      return "무기한";
    } else {
      return `${cpn.startDay} ~ ${cpn.endDay}`;
    }
  }

  limitDetail = () => {
    const { cpnLimit, serviceCd } = this._obj;
    let res = { isPush: true, msg: ``, title: "전체상품", type: "all" };

    if (!isNil(cpnLimit)) {
      if (cpnLimit?.limitCd === "rt_cd") {
        let rtCdKo = "";
        if (serviceCd) {
          rtCdKo = serviceCd?.rtCd.value.find(
            (c: any) => c.key === cpnLimit?.targetId
          )?.ko;
        }
        res.msg = `${rtCdKo} 경로에서만 선택 가능`;
        res.title = rtCdKo;
        res.type = "rtCd";
      } else if (cpnLimit?.limitCd === "rt_id") {
        res.msg = `S${cpnLimit.targetId} 경로에서만 선택 가능`;
        res.title = cpnLimit.targetId;
        res.type = "rtId";
      } else if (cpnLimit?.limitCd === "board_id") {
        res.msg = `${cpnLimit.targetId} 탑승정보에서만 사용 가능`;
        res.title = cpnLimit.targetId;
        res.type = "boardId";
      } else if (cpnLimit?.limitCd === "pay_id") {
        res.msg = `${cpnLimit.targetId} 결제정보에서만 사용 가능`;
        res.title = cpnLimit.targetId;
        res.type = "payId";
      }
    }

    return res;
  };
}

const dateFormatCpn = (date: string) => {
  const c = date.split(" ");
  if (c[1] !== undefined) {
    const dateList = c[0].split("-");
    const timeList = c[1].split(":");
    return `${dateList[0].substring(2)}.${dateList[1]}.${dateList[2]} ${
      timeList[0]
    }:${timeList[1]}`;
  } else {
    const dateList = c[0].split("-");
    return `${dateList[0].substring(2)}.${dateList[1]}.${dateList[2]}`;
  }
};
