import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Body3,
  InputMsg,
  Input,
  Button,
  InputCntn,
} from "ms-ui";
import styled from "styled-components";

export interface IPromo {
  promoInput: IPromoInput;
  promoButton: IPromoButton;
  promoMsg: IPromoMsg;
}

export interface IPromoInput {
  maxLength: number;
  invalid: boolean;
  placeholder: string;
  value: string;
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export interface IPromoButton {
  disabled: boolean;
  onClick: () => void;
}

export interface IPromoMsg {
  active: boolean;
  msg: string;
}

export const Promo = observer(
  ({ promoInput, promoButton, promoMsg }: IPromo) => {
    return (
      <>
        <StDiv1>
          <StDiv2>발급받은 8자리의 프로모션 코드를 입력해 주세요.</StDiv2>
          <InputCntn bottom={`0px`}>
            <StDiv3>
              <Input
                value={promoInput.value}
                placeholder={promoInput.placeholder}
                size={`rg`}
                maxLength={promoInput.maxLength}
                invalid={promoInput.invalid}
                onKeyUp={promoInput.onKeyUp}
                onChange={promoInput.onChange}
              />
              <Button
                color={`primary`}
                size={`rg`}
                radius={`5`}
                outline={false}
                disabled={promoButton.disabled}
                onClick={promoButton.onClick}
                width={`fit-content`}
              >
                쿠폰등록
              </Button>
            </StDiv3>
            <InputMsg
              active={promoMsg.active}
              color={`negative`}
              msg={promoMsg.msg}
            />
          </InputCntn>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[16].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Body3};
  align-self: stretch;
  color: ${contents.subtitle.value};
`;

const StDiv3 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;
