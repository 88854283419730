import { makeObservable, flow } from "mobx";
import { AxiosResponse } from "axios";
import { ICpnData } from "./ICpn";
import { ServerStore } from "ms-ui";
import { get_cpn_list_by_use_cd } from "src/repository/Pay/cpn";

export class CpnStore extends ServerStore<ICpnData | null> {
  constructor() {
    super();
    makeObservable(this, {
      loadData: flow,
    });
  }

  *loadData(useCd: string) {
    try {
      this.setLoading(true);
      const res: AxiosResponse = yield get_cpn_list_by_use_cd({ useCd }).req();
      this.setData(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      this.setLoading(false);
    }
  }
}
